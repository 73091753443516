import { useEffect, useState } from "react";
import AddMember from "./addMember";
import GuestModal from "./guestModal";
import swal from 'sweetalert2';
import { bookTeeTime, updateTeeTime, releasePendingTeeTime, deleteTeeTimeBook, addActivityTracker } from "../api/teeTimeAPI";
import { getAuthState } from "../../assets/scripts/login-util";
 

const BookingModal = (props) => {
    let timerInterval ;
 
    const [timer,        setTimer]        = useState('2:00');    
    const [addModal,     setAddModal]     = useState(false);
    const [members,      setMembers]      = useState([]);
    const [comment,      setComment]      = useState(props.teeTimeBookData.Comment || '');
    const [addGuest,     setAddGuest]     = useState(false);

    useEffect(() => {
        if(props.action == "a") bookTimer();

        const { user } = getAuthState();        
        if(user) setMembers([{ UserID: user.id, UserName: user.userName, UserType:'User'}]);                   

        $('#modalBook').modal({ backdrop: 'static', keyboard: false });
        $('#modalBook').on('hidden.bs.modal', function () {
            $('.modal').hasClass('show') && $('body').addClass('modal-open');
            props.onDismissModal(false);
        }); 
        
        return () => clearInterval(timerInterval);  // cleanup effect 
    }, [])

    useEffect(() => {
        if(addModal) $('#modalBook').addClass('modal-blur');        
    }, [addModal]);
    
    useEffect(() => {             
        if((!!props.bookingMembers ) && (props.bookingMembers.length > 0)){
        
            const users = props.bookingMembers.map( b => ({...b, UserName: b.UserName +' ('+ ( (b.UserType === 'User') ? b.MembershipNo : "G" ) + ')' }))
            setMembers(users);
        }
    },[props]);
   
    const addMemberHandler = (type) => {
        if(members.length > 3){
            swal.fire({ icon: 'warning', buttonsStyling: false, text: 'Maximum 4 users are allowed'});            
            return
        }else{
            if(type === "guest")
                setAddGuest(true);
            else
                setAddModal(true);
        }
    }
       
    const removeMemberHandler = (index) => {
        setMembers(p => {
            const nA = p.filter((f, i) => index !== i )
            return nA
        })
    }

    const postBooking = async(event) => {
        event.preventDefault();
        
        if(timer == "Timer Expired" && props.action == 'a') {
            return swal.fire({ icon: 'success', timer: 1500, showConfirmButton: false, titleText: 'Booking Time Expired..!'});
        }

        try {
        
            const book   = props.teeTimeBookData;
            book.Comment = comment;

            const userList = members.map( m => ({...m, TeeTimeBookID : book.ID }));         

            swal.fire({
                titleText           : 'Loading',
                text                : 'Please Wait...',
                showConfirmButton   : false,
                allowOutsideClick   : false,
                onOpen: () => {
                    swal.showLoading();
                }
            });

            if(props.action === 'e'){                                         
                await updateTeeTime(book, userList);              
            }else{
                // const currentTimestamp = Date.now();
                // const differenceInSeconds = Math.abs((currentTimestamp - props.timestamp) / 1000);
                // console.log(differenceInSeconds);
                
                // if (differenceInSeconds < 3) {
                //     await deleteTeeTimeBook(book.ID, comment);
                //     $('#modalBook').modal('hide');
                //     await addActivityTracker();
                //     // alert(`The difference is less than 3 seconds! ${differenceInSeconds}`)
                // } 
                await bookTeeTime(userList, book.Comment);
            }
            swal.close();
            $('#modalBook').modal('hide');
            swal.fire({ icon: 'success', timer: 1500, showConfirmButton: false,
                titleText: `TeeTime ${(props.action == 'e' ) ? 'Updated': 'Confirmed'} Successfully`,
            });
            props.refetchTeeTime()              
        } catch (err) {
            swal.close();
            swal.fire({ icon: "error", titleText: "Error!", text: err.message })
            return;
        }
    }

    const cancelBooking = async() => {          
        swal.fire({
            icon : 'warning',
            titleText: 'Are you sure, you want to cancel ?',           
            showCancelButton: true,
            confirmButtonText: 'Yes',           
            cancelButtonText:'No'
        }).then(async(result) => {
            
            if (result.isConfirmed) {
                try {
                    const book = props.teeTimeBookData;  
                    
                    swal.fire({
                        titleText           : 'Loading',
                        text                : 'Please Wait...',
                        showConfirmButton   : false,
                        allowOutsideClick   : false,
                        onOpen: () => {
                            swal.showLoading();
                        }
                    });

                    await deleteTeeTimeBook(book.ID, comment);
            
                    swal.close();
                    $('#modalBook').modal('hide');
                    swal.fire({ icon: 'success', timer: 1500, showConfirmButton: false,
                        titleText: 'TeeTime Cancelled Successfully',
                    });

                    props.refetchTeeTime();                                
                } catch (err) {
                    swal.close();
                    swal.fire({ icon: "error", titleText: "Error!", text: err.message })
                    return;
                }                                    
            } 
        })
    }

    const releaseTeeSlot = async()=> {
        try {            
            await releasePendingTeeTime(props.teeTimeBookData.ID); // release api 
        } catch (err) {
            swal.fire({ icon: "error",titleText: "Error!",text: err.message });
            return;
        }
    }

    const onBookingModalClose = ()=> {
        if(props.action != "a") return  $('#modalBook').modal('hide');        

        swal.fire({
            icon : 'warning',
            titleText: 'Are you sure, you want to close, your teetime will be released ?',           
            showCancelButton: true,
            confirmButtonText: 'Yes',           
            cancelButtonText:'No'
        }).then(async(result) => {
            if (result.isConfirmed) {
                $('#modalBook').modal('hide');            
                releaseTeeSlot();
            } 
        })
    };
    
    const bookTimer =()=> {
        let t = 119; // 2 minutes in seconds

        timerInterval = setInterval(()=>{
            if(t > 0){
                const d  = (t % (60 * 60));
                const ds = Math.ceil(d % 60);
                const ms = { m: Math.floor(d / 60), s: ds < 10 ? (`0${ds}`): ds };
                t = t - 1;
                setTimer(`${ms.m}:${ms.s}`);                
            }else{
                t = 0;
                if (timerInterval) clearInterval(timerInterval);
                setTimer('Time Expired');
            }
        }, 1000);
    }

    const commentHandler = (c) => setComment(c);

    return(<>
        <div id="modalBook" className="modal fade modal-responsive" tabIndex="-1" role="dialog">
            <div className="modal-dialog modal-lg" role="document">
                <div className="modal-content">
                    <div className="modal-header">                       
                        <div className="row">
                            <div className="col-xs-5">
                                <h4 id="modalBookTitle" className="modal-title">Book</h4>
                            </div>
                            <div className="col-xs-7 padding-l50">
                                {(props.action == "a") && ( <h5 id="otpTimer" className="margin-t10 margin-l40">{timer}</h5> )}
                            </div>
                        </div>
                    </div>
                    <div className="modal-body padding-t0">
                        <div id="ModalHolder1">
                                                       
                            <div id="bookingForm" role="form">
                                <div className="form-group">
                                    <div className="input-group">
                                        <span className="input-group-addon"> <i className="fa fa-lock fa-lg"></i> </span>
                                        <input 
                                            id="lblDate" 
                                            name="lblDate" 
                                            className="form-control" 
                                            type="text" 
                                            readOnly="readOnly" 
                                            placeholder="Date"
                                            value={moment(props.SelectedDate).format('DD-MMM-YYYY')}
                                        />
                                    </div>
                                </div>

                                <div id="pnlSlot1" className="form-group">
                                    <div className="input-group">
                                        <span className="input-group-addon"> <i id="icSlot1" className="fa fa-lock fa-lg"></i></span>
                                        <input 
                                            id="lblSlot" 
                                            name="lblSlot" 
                                            className="form-control" 
                                            type="text" 
                                            readOnly="readOnly" 
                                            data-id="" 
                                            placeholder="Slot" 
                                            value={props.teeTimeBookData.Slot}
                                        />
                                    </div>
                                </div>

                                {(props.action === "a" || props?.editValidation?.IsEdit) && (<>                                                                 
                                    <div id="pnlMember" className="form-group margin-t20 margin-b0">
                                        <div className="row">
                                            <div className="col-xs-12">
                                                <div className="col-xs-6">
                                                    <button id="btnAddMember" className="btn btn-default btn-block btn-add padding-2" type="button" onClick={() => addMemberHandler('member')}>
                                                        Add Member
                                                    </button>
                                                </div>
                                                <div className="col-xs-6">
                                                    <button id="btnAddGuest" className="btn btn-default btn-block btn-add padding-2" type="button"  onClick={() => addMemberHandler('guest')}>
                                                        Add Guest
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>                                        
                                </>)}                               
                            </div>
                            <div className="row ">
                                <div className="col-xs-12">
                                    <hr className="divider" />
                                </div>
                            </div>

                            <table id="table-user" className="table table-bordered table-hover ">
                                <thead className="thin-border-bottom">
                                    <tr>
                                        <th>Players</th>
                                        <th></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {members.length > 0 && members.map((m, i) =>  (
                                        <tr key={i} >
                                            <td >
                                                {m.UserName}
                                            </td>
                                            {(i !== 0 && (props.action == 'a' || props?.editValidation?.IsEdit)) && (
                                                <td style={{width: "10%"}}>          
                                                    <center>              
                                                        <button className="btn btn-xs btn-danger btn-user-delete" type="button" onClick={() => removeMemberHandler(i)}>                 
                                                            <i className="fa fa-trash-o icon-only"></i>              
                                                        </button>          
                                                    </center>      
                                                </td>
                                            )}
                                        </tr>
                                    ))}
                                </tbody>
                                <tfoot></tfoot>
                            </table>
                            <hr className="divider" />
                            <div className="form-group">
                                <textarea 
                                    name="txtComment" 
                                    rows="2" 
                                    cols="20" 
                                    id="txtComment" 
                                    className="form-control"
                                    placeholder="Comments"
                                    value={comment || ''}
                                    onChange={({ target }) => commentHandler(target.value)}
                                ></textarea>
                            </div>                            

                        </div>
                    </div>

                    <div className="modal-footer">                       
                        <button type="button" id="btnModalClose" className="btn btn-default btn-booking-close margin-r5" onClick={onBookingModalClose}> Close </button>
                        
                        {(props.action == 'e') ?(<>
                            {props?.editValidation?.IsCancel && (
                                <button type="button" id="btnCancel" name="btnCancel" className="btn btn-danger margin-r5" onClick={cancelBooking}> Cancel </button>
                            )}

                            {props?.editValidation?.IsEdit && (
                                <button type="button" id="btnUpdate" name="btnUpdate" className="btn btn-success" onClick={postBooking}> Update </button>
                            )}
                        </>):(
                            <button type="button" id="btnSave" name="btnSave" className="btn btn-success" onClick={postBooking}> Book </button>                                               
                        )}                                            
                    </div>
                </div>
            </div>
        </div>

        {addModal && (
            <AddMember
                show            = {addModal}
                from            = "#modalBook"
                members         = {members}
                setMembers      = {v => setMembers(v)}    
                onDismissModal  = {() => setAddModal(false) }
            />
        )}

        {addGuest && (
            <GuestModal
                show           = {addGuest}              
                from           = "#modalBook"
                members        = {members}
                setMembers     = {v => setMembers(v)}                
                onDismissModal = {() => setAddGuest(false) }
            />
        )}
    </>)
}

export default BookingModal;