import React, { useState, useEffect } from 'react';
import { BrowserRouter, Route, Switch, Redirect } from 'react-router-dom';
import Master from './Master';
import Login from '../components/login/login';
import Register from '../components/login/register';
import Forgot from '../components/login/forgot';
import Otp from '../components/login/otp';
import { getAuthState } from '../assets/scripts/login-util';
import { redirectToLogout } from "../components/api/common";
import Reset from '../components/login/reset';
import VerifyEmail from '../components/login/verifyEmail';
import Invoice from '../components/paymentGateway/invoice';
import GameVerification from '../components/scorecard/gameVerification';
import MembershipForm from '../components/onboarding/membershipForm';
import { signatureContext } from '../context/context';
import UserOnboarded from '../components/onboarding/userOnboarded';
import Assessment from '../components/assessment/assessment';
 
const App = () => { 
    const [auth, setAuth] = useState(getAuthState('bool'));
    const [signatureImg,    setSignatureImg]            = useState(null);

    useEffect(() => {
        const authorized = getAuthState('bool');
        if (authorized === 'Expired') redirectToLogout()
        else {
           setAuth(authorized);
        }
    }, []);

    const authHandler = async () => {
        const authorized = getAuthState('bool');
        authorized === 'Expired' ? setAuth(false) : setAuth(authorized);
    }

    return (
        <BrowserRouter>
        {auth ? (
            <Switch>
                <Route path="/verify-email" exact>
                    <VerifyEmail />
                </Route>
                 <Route path="/invoice" exact>
                    <Invoice />
                </Route>
                <Route path="/membershipForm" exact>
                    <signatureContext.Provider value={signatureImg}>
                        <MembershipForm setSignatureImg={setSignatureImg}/>
                    </signatureContext.Provider>
                </Route>
                <Route path="/user-onboarded" exact>
                    <UserOnboarded />
                </Route>
                <Route path="/game-verification" exact>
                    <GameVerification />
                </Route>
                <Route path="/assessment" exact>
                    <Assessment />
                </Route>
                <Master auth={auth} />
            </Switch>
            
        ) : (
            <Switch>
                <Route path="/login" exact>
                    <Login onLogin={authHandler} />
                </Route>
                <Route path="/registration" exact>
                    <Register onLogin={authHandler} />
                </Route>
                <Route path="/forgot-password" exact>
                    <Forgot onLogin={authHandler} />
                </Route>
                <Route path="/verify-otp" exact>
                    <Otp onLogin={authHandler} />
                </Route>
                <Route path="/reset-password" exact>
                    <Reset onLogin={authHandler} />
                </Route>
                <Route path="/verify-email" exact>
                    <VerifyEmail />
                </Route>
                <Route path="/invoice" exact>
                    <Invoice />
                </Route>
                <Route path="/membershipForm" exact>
                    <signatureContext.Provider value={signatureImg}>
                        <MembershipForm setSignatureImg={setSignatureImg}/>
                    </signatureContext.Provider>
                </Route>
                <Route path="/user-onboarded" exact>
                    <UserOnboarded />
                </Route> 
                <Route path="/assessment" exact>
                    <Assessment />
                </Route>
                <Route path="/game-verification" exact>
                    <GameVerification />
                </Route>
                <Redirect to="/login" />
            </Switch>
        )}           
        </BrowserRouter>
    );

};

export default App;