import { useEffect, useState } from "react";
import bannerLogo from '../../assets/images/CLUBEZ_Login_logo.png';
import './assessment.css';
import ImageCropper from "../global/imageCropper";
import swal from 'sweetalert2';

const Assessment = (props) => {

    const [formDetails,                 setFormDetails]                 = useState({ID: 1, Name: 'Player 1', ProfilePhoto: 'https://img.freepik.com/free-photo/3d-cartoon-individual-playing-tennis_23-2151730835.jpg', FormSubmit: 'Y', Submit: 'Y', Coach: 'Coach 2', TeamName: 'Team 2', FromDate: '01 Apr 2024', ToDate: '01 Jul 2024 ', Age: 21});
    const [rating,                      setRating]                      = useState(0);
    const [signatureCropperData,        setSignatureCropperData]        = useState(null);
    const [signaturePhotoBlob,          setSignaturePhotoBlob]          = useState(null);
 
    const handleRatingClick = (value) => {
      setRating(value);
    };
    
    const updatePrimaryDetails = (field, value) => { 
        setFormDetails((d) => {
            return { ...d, [field]: value };
        });
    };

    const openSignatureCropper = async(e) =>{
        e.preventDefault()
        setSignatureCropperData({
            imageRatio  : { width : 130, height : 35},
            dataHandler : (val) =>  setSignaturePhotoBlob(val),
            header : 'Upload Signature Photo'
        })
    };
 
    const removeSignatureHandler = async(e) => {
		e.preventDefault();
		setSignaturePhotoBlob(null)

	};
 
    const addForm = async(e) => {
		e.preventDefault();
        swal.fire({
            icon: 'success',
            titleText: `Assessment submited Succesfully.`,
            showConfirmButton: false,
            timer: 3000,
        });

	};

    return(
        <>
            <div className='container inputAssessment' style={{width: '1000px'}}>
                <form className="form-horizontal">
                    <div className="panel panel-paper">
                        <div className="panel-body form-onboarding">
                            <div className="panel-heading text-center" style={{marginBottom: '20px'}}>
                                {/* Logo and club name */}
                                <div className="logo-card">
                                    <img className="img-responsive img-login margin-b10 logo-photo" src={bannerLogo} alt=""/>
                                    <div className="logo-title">
                                        <h3 className="panel-title mb-0 padding-t30" style={{fontSize: '36px'}}><strong> Player Evaluation Form</strong></h3>
                                        <p style={{fontSize: '20px'}}>{formDetails.FromDate + ' - ' + formDetails.ToDate}</p>
                                    </div>
                                </div>
                            </div>
                            <fieldset className="primary">
                                <div className="player-card">
                                    <img
                                        src={formDetails.ProfilePhoto || 'default-photo.png'}
                                        alt="Player"
                                        className="player-photo"
                                    />
                                    <div className="player-info">
                                        <h4>{formDetails.Name}</h4>
                                        <h6 style={{fontSize: '15px'}}>Age: {formDetails.Age}</h6>
                                    </div>
                                    <div className="additional-info">
                                        <p className="margin-b0"><strong>Team:</strong>  {formDetails.TeamName}</p>
                                        <p className="margin-t0"><strong>Coach:</strong> {formDetails.Coach}</p>
                                    </div>
                                </div>
                                <div className="form-group">
                                    <div className="matrix-container">
                                        <h3 className="matrix-title">Technical</h3>
                                        <table summary="" aria-labelledby="label_10" cellPadding="4" cellSpacing="0" className="form-matrix-table" data-component="matrix" >
                                            <thead>
                                                <tr className="form-matrix-tr form-matrix-header-tr">
                                                    <th className="form-matrix-th">&nbsp;</th>
                                                    {['Excellent', 'Good', 'Average/Fair', 'Needs work', 'Poor'].map((header, index) => (
                                                        <th scope="col" key={index} className={`form-matrix-headers form-matrix-column-headers form-matrix-column_${index}`}>
                                                            <div className="editor-container editorHasText">
                                                                <div className="inlineEditor" placeholder="Type Col Name" format="text" maxLength="1000">
                                                                {header}
                                                                </div>
                                                            </div>
                                                        </th>
                                                    ))}
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {['Controlling', 'Receiving', 'Dribbling', 'Passing', 'Shooting', 'Crossing', 'Turning', 'Ball Handling', 'Power Kicking', 'Goal Tending', 'Offensive Play', 'Defensive Play'].map((rowName, rowIndex) => (
                                                    <tr className="form-matrix-tr form-matrix-value-tr" key={rowIndex} aria-labelledby={`label_10 label_10_row_${rowIndex}`}>
                                                        <th scope="row" className="form-matrix-headers form-matrix-row-headers">
                                                            <div className="editor-container editorHasText">
                                                                <div className="inlineEditor" placeholder="Type Row Name" format="text" maxLength="1000">
                                                                    {rowName}
                                                                </div>
                                                            </div>
                                                        </th>
                                                        {['Excellent', 'Good', 'Average/Fair', 'Needs work', 'Poor'].map((header, colIndex) => (
                                                            <td className="form-matrix-values" key={colIndex}>
                                                                <input
                                                                    id={`input_10_${rowIndex}_${colIndex}`}
                                                                    className="form-radio"
                                                                    type="radio"
                                                                    name={`q10_technical[${rowIndex}]`} // Unique name for first table
                                                                    aria-labelledby={`label_10_col_${colIndex} label_10_row_${rowIndex}`}
                                                                    aria-label="Cells Radio Button"
                                                                    value={header}
                                                                    onChange={() => updatePrimaryDetails(rowName, header)}
                                                                    checked={formDetails[rowName] === header}
                                                                />
                                                                <label htmlFor={`input_10_${rowIndex}_${colIndex}`} className="matrix-choice-label matrix-radio-label" aria-hidden="true"></label>
                                                            </td>
                                                        ))}
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                                <div className="form-group">
                                    <div className="matrix-container">
                                        <h3 className="matrix-title">Tactical/Psychological/Physical</h3>
                                        <table summary="" aria-labelledby="label_11" cellPadding="4" cellSpacing="0" className="form-matrix-table" data-component="matrix">
                                            <thead>
                                                <tr className="form-matrix-tr form-matrix-header-tr">
                                                    <th className="form-matrix-th">&nbsp;</th>
                                                    {['Excellent', 'Good', 'Average/Fair', 'Needs work', 'Poor'].map((header, index) => (
                                                        <th scope="col" key={index} className={`form-matrix-headers form-matrix-column-headers form-matrix-column_${index}`}>
                                                            <div className="editor-container editorHasText">
                                                                <div className="inlineEditor" placeholder="Type Col Name" format="text" maxLength="1000">
                                                                {header}
                                                                </div>
                                                            </div>
                                                        </th>
                                                    ))}
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {['Sportsmanship', 'Disciplined', 'Focused', 'Confidence', 'Decision making', 'Can perform under pressure', 'Commitment', 'Leadership', 'Teamwork', 'Physical Attributes'].map((rowName, rowIndex) => (
                                                    <tr className="form-matrix-tr form-matrix-value-tr" key={rowIndex} aria-labelledby={`label_11 label_11_row_${rowIndex}`}>
                                                        <th scope="row" className="form-matrix-headers form-matrix-row-headers">
                                                            <div className="editor-container editorHasText">
                                                                <div className="inlineEditor" placeholder="Type Row Name" format="text" maxLength="1000">
                                                                    {rowName}
                                                                </div>
                                                            </div>
                                                        </th>
                                                        {['Excellent', 'Good', 'Average/Fair', 'Needs work', 'Poor'].map((header, colIndex) => (
                                                            <td className="form-matrix-values" key={colIndex}>
                                                                <input
                                                                    id={`input_11_${rowIndex}_${colIndex}`}
                                                                    className="form-radio"
                                                                    type="radio"
                                                                    name={`q11_tactical[${rowIndex}]`} // Unique name for second table
                                                                    aria-labelledby={`label_11_col_${colIndex} label_11_row_${rowIndex}`}
                                                                    aria-label="Cells Radio Button"
                                                                    value={header}
                                                                    onChange={() => updatePrimaryDetails(rowName, header)}
                                                                    checked={formDetails[rowName] === header}
                                                                />
                                                                <label htmlFor={`input_11_${rowIndex}_${colIndex}`} className="matrix-choice-label matrix-radio-label" aria-hidden="true"></label>
                                                            </td>
                                                        ))}
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>

                                <div className="form-group">
                                <div>
                                <h3>Rating Scale</h3>
                                <table style={{ width: '100%', borderCollapse: 'collapse', margin: '20px 0' }}>
                                    <thead>
                                    <tr>
                                        <th style={{ border: '1px solid #dddddd', padding: '8px', textAlign: 'center' }}>Rating</th>
                                        <th style={{ border: '1px solid #dddddd', padding: '8px' }}>Description</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    <tr>
                                        <td style={{ border: '1px solid #dddddd', textAlign: 'center', padding: '8px' }}>5</td>
                                        <td style={{ border: '1px solid #dddddd', padding: '8px' }}>Excellent</td>
                                    </tr>
                                    <tr>
                                        <td style={{ border: '1px solid #dddddd', textAlign: 'center', padding: '8px' }}>4</td>
                                        <td style={{ border: '1px solid #dddddd', padding: '8px' }}>Good</td>
                                    </tr>
                                    <tr>
                                        <td style={{ border: '1px solid #dddddd', textAlign: 'center', padding: '8px' }}>3</td>
                                        <td style={{ border: '1px solid #dddddd', padding: '8px' }}>Average/Fair</td>
                                    </tr>
                                    <tr>
                                        <td style={{ border: '1px solid #dddddd', textAlign: 'center', padding: '8px' }}>2</td>
                                        <td style={{ border: '1px solid #dddddd', padding: '8px' }}>Needs more work</td>
                                    </tr>
                                    <tr>
                                        <td style={{ border: '1px solid #dddddd', textAlign: 'center', padding: '8px' }}>1</td>
                                        <td style={{ border: '1px solid #dddddd', padding: '8px' }}>Poor</td>
                                    </tr>
                                    </tbody>
                                </table>
                                </div>  
                                </div>
                                <div className="form-group">
                                    <div className="question-wrapper questionWrapper">
                                        <label
                                            id="label_13"
                                            className="form-label form-label-top form-label-auto"
                                            htmlFor="none"
                                            style={{ width: '100%' }}
                                        >
                                            <div className="editor-container editorHasText" style={{ display: 'inline-block', width: '100%' }}>
                                            <div className="inlineEditor form-field-label" style={{ width: '100%' }}>
                                                Overall Player Rating
                                            </div>
                                            </div>
                                        </label>
                                        <div className="form-input-wide" data-layout="full" style={{ position: 'relative' }}>
                                            <span className="form-sub-label-container" style={{ verticalAlign: 'top' }}>
                                            <div
                                                className="form-star-rating"
                                                role="radiogroup"
                                                aria-labelledby="label_13"
                                                style={{ cursor: 'pointer', display: 'flex' }}
                                            >
                                                {[1, 2, 3, 4, 5].map((value) => (
                                                <div
                                                    key={value}
                                                    tabIndex={0}
                                                    aria-label={`${value} out of 5`}
                                                    role="radio"
                                                    aria-checked={rating === value}
                                                    style={{
                                                    width: '40px',
                                                    height: '40px',
                                                    color: rating >= value ? '#f5bc2f' : 'gray', // Change star color
                                                    cursor: 'pointer',
                                                    }}
                                                    onClick={() => handleRatingClick(value)}
                                                    onKeyDown={(e) => {
                                                    if (e.key === 'Enter') handleRatingClick(value);
                                                    }}
                                                >
                                                    {/* Star icon using SVG */}
                                                    <svg
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    fill="currentColor"
                                                    viewBox="0 0 24 24"
                                                    style={{ width: '100%', height: '100%' }}
                                                    >
                                                    <path d="M12 .587l3.668 7.429 8.208 1.193-5.94 5.775 1.404 8.186L12 18.896l-7.34 3.865 1.404-8.186-5.94-5.775 8.208-1.193z" />
                                                    </svg>
                                                </div>
                                                ))}
                                                <input type="hidden" name="q13_overallPlayer" value={rating} className="form-textbox form-star-rating-star" />
                                            </div>
                                            <span className="form-sub-label" data-testid="undefined">
                                                <div className="editor-container editorNoText" style={{ display: 'inline-block' }}>
                                                <div className="inlineEditor" style={{ width: '100%' }} />
                                                </div>
                                            </span>
                                            </span>
                                        </div>
                                    </div>             
                                </div>
                                <div className="form-group">
                                    <div className="question-wrapper questionWrapper">
                                        <label
                                            id="label_15"
                                            className="form-label form-label-top form-label-auto"
                                            htmlFor="input_15"
                                            style={{ width: '100%' }}
                                        >
                                            <div className="editor-container editorHasText" style={{ display: 'inline-block', width: '100%' }}>
                                            <div className="inlineEditor form-field-label" style={{ width: '100%' }}>
                                                Coach Feedback
                                            </div>
                                            </div>
                                        </label>
                                        <div className="form-input-wide" data-layout="full" style={{ position: 'relative' }}>
                                            <span className="form-sub-label-container" style={{ verticalAlign: 'top' }}>
                                            <textarea
                                                id="input_15"
                                                className="form-textarea"
                                                name="q15_evaluatorFeedback"
                                                data-component="textarea"
                                                placeholder="Type your feedback here..."
                                                style={{ width: '100%', height: '163px' }}
                                            />
                                            <span className="form-sub-label" data-testid="undefined">
                                                <div className="editor-container editorNoText" style={{ display: 'inline-block' }}>
                                                <div className="inlineEditor" style={{ width: '100%' }} />
                                                </div>
                                            </span>
                                            </span>
                                        </div>
                                    </div>
                                </div>
                                <div className="form-group">
                                    <div className="question-wrapper questionWrapper">
                                        <label
                                            id="label_15"
                                            className="form-label form-label-top form-label-auto"
                                            htmlFor="input_15"
                                            style={{ width: '100%' }}
                                        >
                                            <div className="editor-container editorHasText" style={{ display: 'inline-block', width: '100%' }}>
                                            <div className="inlineEditor form-field-label" style={{ width: '100%' }}>
                                                Evaluator Feedback
                                            </div>
                                            </div>
                                        </label>
                                        <div className="form-input-wide" data-layout="full" style={{ position: 'relative' }}>
                                            <span className="form-sub-label-container" style={{ verticalAlign: 'top' }}>
                                            <textarea
                                                id="input_15"
                                                className="form-textarea"
                                                name="q15_evaluatorFeedback"
                                                data-component="textarea"
                                                placeholder="Type your feedback here..."
                                                style={{ width: '100%', height: '163px' }}
                                            />
                                            <span className="form-sub-label" data-testid="undefined">
                                                <div className="editor-container editorNoText" style={{ display: 'inline-block' }}>
                                                <div className="inlineEditor" style={{ width: '100%' }} />
                                                </div>
                                            </span>
                                            </span>
                                        </div>
                                    </div>
                                </div>
                                <div className="form-group">
                                    <div className="question-wrapper questionWrapper">
                                        <label
                                            id="label_15"
                                            className="form-label form-label-top form-label-auto"
                                            htmlFor="input_15"
                                            style={{ width: '100%' }}
                                        >
                                            <div className="editor-container editorHasText" style={{ display: 'inline-block', width: '100%' }}>
                                            <div className="inlineEditor form-field-label" style={{ width: '100%' }}>
                                            Strengths
                                            </div>
                                            </div>
                                        </label>
                                        <div className="form-input-wide" data-layout="full" style={{ position: 'relative' }}>
                                            <span className="form-sub-label-container" style={{ verticalAlign: 'top' }}>
                                            <textarea
                                                id="input_15"
                                                className="form-textarea"
                                                name="q15_evaluatorFeedback"
                                                data-component="textarea"
                                                placeholder="Type your feedback here..."
                                                style={{ width: '100%', height: '163px' }}
                                            />
                                            <span className="form-sub-label" data-testid="undefined">
                                                <div className="editor-container editorNoText" style={{ display: 'inline-block' }}>
                                                <div className="inlineEditor" style={{ width: '100%' }} />
                                                </div>
                                            </span>
                                            </span>
                                        </div>
                                    </div>
                                </div>
                                <div className="form-group">
                                    <div className="question-wrapper questionWrapper">
                                        <label
                                            id="label_15"
                                            className="form-label form-label-top form-label-auto"
                                            htmlFor="input_15"
                                            style={{ width: '100%' }}
                                        >
                                            <div className="editor-container editorHasText" style={{ display: 'inline-block', width: '100%' }}>
                                            <div className="inlineEditor form-field-label" style={{ width: '100%' }}>
                                                Weaknesses
                                            </div>
                                            </div>
                                        </label>
                                        <div className="form-input-wide" data-layout="full" style={{ position: 'relative' }}>
                                            <span className="form-sub-label-container" style={{ verticalAlign: 'top' }}>
                                            <textarea
                                                id="input_15"
                                                className="form-textarea"
                                                name="q15_evaluatorFeedback"
                                                data-component="textarea"
                                                placeholder="Type your feedback here..."
                                                style={{ width: '100%', height: '163px' }}
                                            />
                                            <span className="form-sub-label" data-testid="undefined">
                                                <div className="editor-container editorNoText" style={{ display: 'inline-block' }}>
                                                <div className="inlineEditor" style={{ width: '100%' }} />
                                                </div>
                                            </span>
                                            </span>
                                        </div>
                                    </div>
                                </div>
                                <div className="form-group">
                                    <div className="question-wrapper questionWrapper">
                                        <label
                                            id="label_15"
                                            className="form-label form-label-top form-label-auto"
                                            htmlFor="input_15"
                                            style={{ width: '100%' }}
                                        >
                                            <div className="editor-container editorHasText" style={{ display: 'inline-block', width: '100%' }}>
                                            <div className="inlineEditor form-field-label" style={{ width: '100%' }}>
                                                Areas of Improvement
                                            </div>
                                            </div>
                                        </label>
                                        <div className="form-input-wide" data-layout="full" style={{ position: 'relative' }}>
                                            <span className="form-sub-label-container" style={{ verticalAlign: 'top' }}>
                                            <textarea
                                                id="input_15"
                                                className="form-textarea"
                                                name="q15_evaluatorFeedback"
                                                data-component="textarea"
                                                placeholder="Type your feedback here..."
                                                style={{ width: '100%', height: '163px' }}
                                            />
                                            <span className="form-sub-label" data-testid="undefined">
                                                <div className="editor-container editorNoText" style={{ display: 'inline-block' }}>
                                                <div className="inlineEditor" style={{ width: '100%' }} />
                                                </div>
                                            </span>
                                            </span>
                                        </div>
                                    </div>
                                </div>
                                <div className="form-group">
                                    <div className="question-wrapper questionWrapper">
                                        <label
                                            id="label_15"
                                            className="form-label form-label-top form-label-auto"
                                            htmlFor="input_15"
                                            style={{ width: '100%' }}
                                        >
                                            <div className="editor-container editorHasText" style={{ display: 'inline-block', width: '100%' }}>
                                            <div className="inlineEditor form-field-label" style={{ width: '100%' }}>
                                                Goals / Plans / Objectives
                                            </div>
                                            </div>
                                        </label>
                                        <div className="form-input-wide" data-layout="full" style={{ position: 'relative' }}>
                                            <span className="form-sub-label-container" style={{ verticalAlign: 'top' }}>
                                            <textarea
                                                id="input_15"
                                                className="form-textarea"
                                                name="q15_evaluatorFeedback"
                                                data-component="textarea"
                                                placeholder="Type your feedback here..."
                                                style={{ width: '100%', height: '163px' }}
                                            />
                                            <span className="form-sub-label" data-testid="undefined">
                                                <div className="editor-container editorNoText" style={{ display: 'inline-block' }}>
                                                <div className="inlineEditor" style={{ width: '100%' }} />
                                                </div>
                                            </span>
                                            </span>
                                        </div>
                                    </div>
                                </div>
                                <div className="form-group">
                                    <div className="text-center" style={{ margin: '21px 42px auto' }}>
                                        {/* Signature uploader */}
                                        <div className="kt-avatar kt-avatar--outline kt-avatar--changed offset-4" id="kt_user_add_avatar">                                              
                                            {(signaturePhotoBlob) ? <div className="btn btn-default upload-button" style={{ backgroundImage: `url(${signaturePhotoBlob ? URL.createObjectURL(signaturePhotoBlob) : groupDetails.MemberSignature && groupDetails.MemberSignature })`, width: "190px", height: "50px", backgroundSize: "cover", backgroundPosition: "center"}}></div> : <button type="button" className="btn btn-default upload-button" style={{width: "130px", height: "35px" }} onClick={openSignatureCropper}> Signature</button>}
                                            {(!!signaturePhotoBlob) && (
                                                <span className="avatar__cancel_signature" data-toggle="kt-tooltip" data-original-title="Cancel avatar" onClick={removeSignatureHandler}>
                                                    <div className="pencil-container">
                                                        <i className="fa fa-times"></i>
                                                        <span className="add-text">Delete</span>
                                                    </div>
                                                </span>
                                            )}
                                        </div>
                                    </div>
                                </div>
                                <div className="form-group center-form">
                                    <input 
                                        type="date" 
                                        className="rectangular-input"   
                                        id="inputDOB1" 
                                        name="DOB" 
                                        value={formDetails.Date ? moment(formDetails.Date).format('YYYY-MM-DD') : ''} 
                                        onChange={(event) => updatePrimaryDetails('Date', event.target.value)}
                                    />
                                </div>
                            </fieldset>
                        </div>
                    </div>
                </form>
                <nav className="navbar navbar-default navbar-fixed-bottom" style={{boxShadow: "0px 0px 4px rgba(0, 0, 0, 0.3)"}}>
                    <div className="container-fluid"> 
                        <form className="navbar-form navbar-right" role="search">
                            <button type="save" className="btn btn-default margin-r10 btn-primary" onClick={addForm}>Submit</button>
                        </form>
                    </div>
                </nav>
            </div>
            {signatureCropperData && (
                <ImageCropper
                    show           = { signatureCropperData }
                    header         = { signatureCropperData.header }   
                    imageRatio     = { signatureCropperData.imageRatio }
                    dataHandler    = { signatureCropperData.dataHandler }
                    onDismissModal = {() => setSignatureCropperData(null) }
                />
            )}
        </>
    )
};

export default Assessment;